///////////////////////////////////////////////////
///        INCLUDED THIRD-PARTY VENDORS         ///
///////////////////////////////////////////////////

// Include NioIcon 
@import "nioicon/nioicon";

// Include Prettify 
@import "prettify";

// Include Select2 
@import "select2/core.scss";

// Include Simplebar
@import "simplebar";
.simplebar-scroll-content {
	@extend .simplebar-content-wrapper;
}

// Include Slick
@import "slick/slick";

// Include datepicker
@import "datepicker/bootstrap-datepicker";

// Include time
@import "timepicker/jquery.timepicker";

// Include dropzone
@import "dropzone/dropzone";

// Include nouislider
@import "nouislider/nouislider";

// Include toastr
@import "toastr/toastr";

// Include sweetalert2
@import "sweetalert2/sweetalert2";

// Include DataTable
@import "datatable/datatable";

// Include JQVmap
@import "jqvmap";

// Include tagify
@import "tagify";

// Include fullcalendar
@import "fullcalendar";

// Include jkanban
@import "jkanban";