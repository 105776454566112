///////////////////////////////////
/// Special Table | .tb-tnx
///////////////////////////////////
.tb-tnx {
    &-head {
        font-size: $fx-sz-11;
        font-weight: $fw-bold;
        text-transform: uppercase;
        letter-spacing: 0.12em;
        background: $lighter;
        td, th {
            color: $base-light;
            padding: 0.625rem .25rem;
        }
        th {
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
        }
    }
    &-item {
        font-size: $fx-sz-14;
        td{
            padding: 1.25rem .25rem;
            vertical-align: middle;
            .is-compact & {
                padding-top: .4rem;
                padding-bottom: .4rem;
            }
        }
    }
    &-id {
        a {
            display: block;
        }
        span {
            font-weight: $fw-medium;
        }
    }
    &-total {
        .tb-tnx-item & {
            font-weight: $fw-medium;
            color: $body-color;
        }
    }
    &-desc {
        @if($rtl==false){
            padding-right: .5rem;
        }
        @if($rtl==true){
            padding-left: .5rem;
        }
        .tb-tnx-item & {
            @extend .ellipsis;
        }
    }
    &-date {
        .tb-tnx-item & {
            color: $base-light;
            .date {
                font-size: $fx-sz-13;
            }
        }
    }
    &-action{
        width: 60px;
        @if($rtl==false){
            text-align: right;
        }
        @if($rtl==true){
            text-align: left;
        }
        .dropdown {
            margin-top: -0.25rem;
            margin-bottom: -0.25rem;
        }
    }
    &-btns {
    	.btn + .btn {
            @if($rtl==false){
                margin-left: .5rem;
            }
            @if($rtl==true){
                margin-right: .5rem;
            }
    	}
    	& + .dropdown {
            @if($rtl==false){
                margin-left: 1rem;
            }
            @if($rtl==true){
                margin-right: 1rem;
            }
    	}
    }
}
@media (max-width: 767px){
    .tb-tnx{
        &-item, &-head {
            display: flex;
            width: 100%;
            th {
                flex-grow: 1;
                padding-top: .5rem;
                padding-bottom: .5rem;
            }
            td{
                flex-grow: 1;
                border: none;
                padding-top: 0;
                padding-bottom: 0;
            }
        }

        &-item {
            flex-wrap: wrap;
            padding-top: 1rem;
            padding-bottom: 1rem;
            &:not(:last-child){
                border-bottom: 1px solid $border-light;
            }
        }
        &-amount{
            width: 110px;
            @if($rtl==false){
                text-align: right;
            }
            @if($rtl==true){
                text-align: left;
            }
        }
        &-id, &-head th:first-child {
            min-width: 85px;
            @if($rtl==false){
                padding-right: .25rem !important;
            }
            @if($rtl==true){
                padding-left: .25rem !important;
            }
            flex-grow: 0 !important;
        }
        &-info{
            width: 45%;
        }
        &-date {
            .date {
                font-size: $fx-sz-12;
                & + .date {
                    @if($rtl==false){
                        padding-left: .5rem;
                    }
                    @if($rtl==true){
                        padding-right: .5rem;
                    }
                }
            }
        }

        &-action{
            &:last-child{
                flex-grow: 0;
                @if($rtl==false){
                    padding-left: .5rem;
                }
                @if($rtl==true){
                    padding-right: .5rem;
                }
                display: inline-flex;
                align-items: center;
            }
        }
    }
}

@media (max-width: 575px){
    .tb-tnx {
        &-item {
            font-size: $fx-sz-13;
        }
        &-id {
            width: 100%;
            + td {
                @if($rtl==false){
                    padding-left: 1.25rem;
                }
                @if($rtl==true){
                    padding-right: 1.25rem;
                }
            }
        }
        &-info{
            width: 65%;
        }
        &-amount{
            &:last-child {
                width: 25%;
            }
            &:not(:last-child) {
                display: none;
            }
            &.is-alt{
                position: absolute;
                display: inline-flex;
                @if($rtl==false){
                    right: 1.25rem;
                }
                @if($rtl==true){
                    left: 1.25rem;
                }
                width: auto;
                .tb-tnx-total {
                    order: 5;
                    @if($rtl==false){
                        padding-left: 1.25rem;
                    }
                    @if($rtl==true){
                        padding-right: 1.25rem;
                    }
                }
            }
        }
    }
}

@media (min-width: 768px){
    .tb-tnx {
        &-desc, &-date, &-total, &-status {
            display: inline-block;
            vertical-align: middle;
        }
        &-desc, &-date {
            width: calc(50% - 4px);
        }
        &-total {
            width: calc(60% - 4px);
        }
        &-status{
            width: calc(40% - 4px);
        }
        &-amount {
            width: 25%;
        }
        &-date {
            .date, > span > span {
                width: calc(50% - 4px);
                display: inline-block;
            }
            .date + .date, > span > span + span {
                @if($rtl==false){
                    padding-left: 0.75rem;
                }
                @if($rtl==true){
                    padding-right: 0.75rem;
                }
            }
        }
    }
}