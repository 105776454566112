.nk-slider{
    position: relative;
    &-s1{
        width: 290px;
        max-width: 100%;
        .slick-dots{
            position: absolute;
            @if($rtl==false){
                right: 1.25rem;
            }
            @if($rtl==true){
                left: 1.25rem;
            }
            top: 1.5rem;
        }
    }
    &-s2{
        .slider-arrows{
            > div{
                padding: .5rem 0;
            }
        }
    }
}