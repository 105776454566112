//Nunito
@font-face {
    font-family: 'Nunito';
    src: url('../../../fonts/Nunito-Regular.eot');
    src: local('Nunito Regular'), local('Nunito-Regular'),
        url('../../../fonts/Nunito-Regular.eot?#iefix') format('embedded-opentype'),
        url('../../../fonts/Nunito-Regular.woff2') format('woff2'),
        url('../../../fonts/Nunito-Regular.woff') format('woff'),
        url('../../../fonts/Nunito-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Nunito';
    src: url('../../../fonts/Nunito-Bold.eot');
    src: local('Nunito Bold'), local('Nunito-Bold'),
        url('../../../fonts/Nunito-Bold.eot?#iefix') format('embedded-opentype'),
        url('../../../fonts/Nunito-Bold.woff2') format('woff2'),
        url('../../../fonts/Nunito-Bold.woff') format('woff'),
        url('../../../fonts/Nunito-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}