// @conditiona -s-
@if($gd_investment==true) {

.card{
    &-title{
        .subtitle{
            color: $base-light;
            font-size: $fx-sz-13;
            font-weight: $fw-medium;
        }
    }
    &-amount{
        display: flex; 
        align-items: baseline;
        .change{
            @if($rtl==false){
                margin-left: .5rem;
            }
            @if($rtl==true){
                margin-right: .5rem;
            }
        }
        .amount{
            font-size: 1.5rem;
            color: $base-color;
            span {
                color: $base-text;
            }
        }
    }
}

@include media-breakpoint-only(md){
    .card{
        &-amount{
            .amount{
                font-size: 1rem;
                font-weight: $fw-medium;
                span {
                    font-weight: $fw-normal;
                }
            }
            .change{
                font-size: 12px;
            }
        }
    }
}

.nav-tabs-card{
    .card-inner &{
        margin-left: -1.25rem;
        margin-right: -1.25rem;
    }
}

@include media-breakpoint-up(sm){
    .nav-tabs-card{
        .card-inner &{
            margin-left: -1.5rem;
            margin-right: -1.5rem;
        }
    }    
}
.nav-tabs{
    &-xs{
        .nav-link{
            padding:  .75rem 0;
            font-size: $fx-sz-13;
            font-weight: $fw-medium;
        }
    }
}
@include media-breakpoint-up(md){
    .nav-tabs{
        &-xs{
            .nav-item{
                @if($rtl==false){
                    padding-right:1.25rem;
                }
                @if($rtl==true){
                    padding-left:1.25rem;
                }
            }
        }
    }
}
@include media-breakpoint-up(lg){
    .nav-tabs{
        &-sm{
            .nav-item{
                @if($rtl==false){
                    padding-right:1.25rem;
                }
                @if($rtl==true){
                    padding-left:1.25rem;
                }
            }
        }
    }
}
@include media-breakpoint-up(xl){
    .nav-tabs{
        &-sm{
            .nav-item{
                @if($rtl==false){
                    padding-right:1.25rem;
                }
                @if($rtl==true){
                    padding-left:1.25rem;
                }
            }
        }
    }
}

} // @condition -e-