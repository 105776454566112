/*!
* Template Name: DashLite
* Author: Softnio
* Author URI: http://themeforest.net/user/softnio
* Version: 2.2.0
* Updated: 11.28.2020
**/

/* 
 * IMPORTANT NOTE: DO NOT Edit this file. Best to write own code in css/theme.css file.
 * It will helps you to easy update of the template.
 **/

/*
----------------------
## Table Contents ##
* 01. FONTS
* 02. VENDORS (Bootstraps, NioIcon, Select2 etc)
* 03. LAYOUTS
* 04. UTILITIES
* 05. COMPONENTS
* 06. INDIVIDUAL CONCEPTS
* 07. PREVIEWS
---------------------- **/

////////////////////////// DO NOT REMOVE BELOW ///////////////////////

// SET RTL OPTION
$rtl: false;

// Included Global Variables and Functions
@import "dashlite_variables";

// Included Fonts
/** 01. FONTS */
@import "core/fonts/roboto";
@import "core/fonts/nunito";

// Included Bootstarp
@import "extend/bootstrap/variables";
@import "extend/bootstrap/bootstrap";
@import "extend/bootstrap/extend";

// Include Other Vendors
@import "vendors/bundle";

// Include Core Dashlite Style
@import "core/style";

////////////////////////// DO NOT REMOVE ABOVE ///////////////////////

////// GLOBAL INCLUDED
@import "global/style";

//////  INDIVIDUAL CONCEPTS
@import "subscription/style";

//////  APPLICATIONS - Common Uses
@import "apps/asterisk"; 
@import "apps/reply-comment"; 
@import "apps/attach"; 

//////  Apps
@import "apps/messages";  		// - Support Messages
@import "apps/inbox";  			// - Inbox/ Mail App
@import "apps/file-manager";  	// - File Manager
@import "apps/chats";  			// - Chats

///////  PREVIEW PURPOSE 
@import "core/preview";			// Can be remove on real project

///////  UI/MODE OVERRIDE
@import "core/mode-override";

///////  DARK MODE SKIN
@import "core/dark-skin";

.marqet-logo {
  color: $yellow !important;
  font-weight: 700 !important;
}

@media (max-width: 992px) {
  .homepage-img {
    max-width: 300px !important;
  }

  .homepage-logo-size {
    @include font-size($h2-fs-lg); letter-spacing: -0.03em;
  }
}

.homepage-logo-size {
  @extend .h3;
}

/*! END @iO */
////////////////////////// END STYLESHEET ////////////////////////////