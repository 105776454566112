// /// Subscription Plans
// ///////////////////////
.sp-plan {
	&-name {
		font-size: $fx-sz-13;
		color: $base-light;
		.badge {
			@if($rtl==false){
				margin-left: .5rem;
			}
			@if($rtl==true){
				margin-right: .5rem;
			}
        }
	}
	&-opt{
		width: 100%;
		label{
            font-size: $fx-sz-12;
            font-weight: $fw-medium;
        }
	}
	&-desc {
		border-top: 1px solid $border-light;
		padding-top: 1rem;
		margin-bottom: -0.5rem;
		li {
			padding: 0.375rem 0;
			font-weight: $fw-medium;
		}
		p > span {
			font-weight: $fw-normal;
			display: block;
		}
	}
	&-action {
		background: $lighter;
		border-top: 1px solid $border-color;
		border-radius: 0 0 $border-radius $border-radius;
	}
	&-note {
		margin-top:.75rem;
		font-style: italic;
		font-size: $fx-sz-12;
		color: $base-light;
		span {
        	color: $base-text;
		}
	}
	
	&-head {
		&-group{
			display: flex;
			justify-content: space-between;
			& + .sp-plan-payopt {
				margin-top: .75rem;
			}
		}
		& + .sp-plan-desc {
			padding-top: .5rem;
			border-top: 0;
			margin-bottom: 0;
		}
		.title {
			line-height: 1.3;
			& + span {
				font-size: $fx-sz-13;
			}
		}
	}
	&-amount {
		flex-shrink: 0;
		font-size: .875rem;
	    color: $base-color;
	    font-weight: $fw-medium;
		@if($rtl==false){
			text-align: right;
		}
		@if($rtl==true){
			text-align: left;
		}
	    line-height: 1.3;
	}
	&-status {
		font-size: $fx-sz-12;
		display: inline-block;
		width: 55px;
		@if($rtl==false){
			text-align: left;
			padding-right: .5rem;
		}
		@if($rtl==true){
			text-align: right;
			padding-left: .5rem;
		}
	}

	&-link {
		position: relative;
		margin: -0.5rem 0;
		.link {
			@if($rtl==false){
				padding: .5rem 1.5rem 0.5rem 0;
			}
			@if($rtl==true){
				padding: .5rem 0 0.5rem 1.5rem;
			}
			display: block;
			> span{
				display: inline-flex;
				align-items: center;
				.icon {
					font-size: 1.5rem;
					@if($rtl==false){
						padding-right: .75rem;
					}
					@if($rtl==true){
						padding-left: .75rem;
					}
				}
			}
			> .icon {
				position: absolute;
				@if($rtl==false){
					right: 0;
                }
                @if($rtl==true){
					left: 0;
                }
				font-size: 1rem;
				top: 50%;
				transform: translateY(-50%);
			}
		}
	}
}

@media (min-width: 414px){
	.sp-plan {
		&-action {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}
		&-note {
			margin-top: 0;
		}
		&-amount {
			font-size: 1rem;
		}
		&-status {
			font-size: $fx-sz-13;
		}
	}
}
@include media-breakpoint-up(md) {
	.sp-plan {
		&-head {
			& + .sp-plan-desc {
				padding-top: 1rem;
			}
		}
		&-desc {
			border-top: 0;
			padding-top: 0;
			margin-top: -0.5rem;
		}
		&-action {
			height: 100%;
	        align-items: center;
	        flex-wrap: wrap;
	        flex-direction: column;
	        justify-content: center;
	        border-top: 0;
			@if($rtl==false){
				border-left: 1px solid $border-color;
				border-radius: 0 $border-radius $border-radius 0;
			}
			@if($rtl==true){
				border-right: 1px solid $border-color;
				border-radius: $border-radius 0 0 $border-radius;
			}
		}
		&-note {
			margin-top:.75rem;
			font-size: $fx-sz-13;
		}
	}
}
@include media-breakpoint-down(xs) {
	.sp-plan-desc-mb {
		p {
			@if($rtl==false){
				text-align: right;
			}
			@if($rtl==true){
				text-align: left;
			}
			 > span {
				 @if($rtl==false){
					text-align: left;
					float: left;
                }
                @if($rtl==true){
					text-align: right;
					float: right;
                }
				width: 45%;
				display: inline-block;
			}
		}
	}
}


// Credit card Dropdown
//////////////////////////////
.cc-pay {
	.title {
		font-size: .875rem;
		color: $base-text;
		font-family: $base-font-family;
	}
	&-method {
		display: flex;
		justify-content: flex-start;
		flex-direction: column;
	}
	&-btn {
		margin-top: 1.25rem;
	}
	&-dd {
		> .btn {
			width: 100%;
			font-size: 1rem;
			font-family: $base-font-family;
			font-weight: $fw-normal;
			span{
				letter-spacing: 0.08em;
			}
			.icon {
				@if($rtl==false){
					margin-right: .25rem;
					margin-left: -0.25rem;
                }
                @if($rtl==true){
					margin-left: .25rem;
					margin-right: -0.25rem;
                }
				font-size: 1.5rem;
			}
		}
		.dropdown-toggle:after {
			position: absolute;
			@if($rtl==false){
				margin-right: -0.25rem;
				padding-left: .5rem;
				right: 1rem;
			}
			@if($rtl==true){
				margin-left: -0.25rem;
				padding-right: .5rem;
				left: 1rem;
			}
		}
		.btn + .dropdown-menu {
			margin-top: -2.25rem;
		}
		.btn-lg + .dropdown-menu {
			margin-top: -2.75rem;
		}
	}
	&-star {
		line-height: 1;
		padding-top: .125rem;
		display: inline-block;
		vertical-align: middle;
	}

	&-item {
		padding: .75rem 1.25rem !important;
		&-icon {
			font-size: 1.5rem;
			width: 2rem;
			flex-shrink: 0;
			display: inline-block;
			@if($rtl==false){
				margin-right: .5rem;
			}
			@if($rtl==true){
				margin-left: .5rem;
			}
		}
		&-name {
			flex-grow: 1;
			display: flex;
			flex-direction: column;
		}
		&-method {
			font-size: $fx-sz-14;
			font-weight: $fw-medium;
			white-space: nowrap;
			overflow: hidden;
    		text-overflow: ellipsis;
    		width: 100%;
    		max-width: 90%;
    		&-new{
    			color: $accent-color;
				font-weight: $fw-medium;
    		}
		}
		&-meta {
			font-weight: $fw-normal;
			color: $base-light;
			font-size: $fx-sz-12;
			font-style: italic;
		}
	}
}

@include media-breakpoint-up(md) {
	.cc-pay {
		&-dd {
			min-width: 280px;
			@if($rtl==false){
				margin-right: 1rem;
			}
			@if($rtl==true){
				margin-left: 1rem;
			}
		}
		&-method {
			flex-direction: row;
		}
		&-btn {
			margin-top: 0;
		}
	}
}

/////////////////////////////
/// Package Plan
/////////////////////////////
.sp-package {
	&-plan {
		margin-top: 2.5rem;
		margin-bottom: 1.5rem;
	}
	&-item:not(:last-child) {
		margin-bottom: 1.25rem;
	}
	&-choose {
        position: absolute;
        opacity: 0;
        height: 1px;
        width: 1px; 
        ~ label {
            position: relative;
            border-width: 2px;
            cursor: pointer;
            transition: border-color 0.4s;
            margin-bottom: 0;
            &:after, &:before {
                position: absolute;
				@if($rtl==false){
					right: 1.25rem;
                }
                @if($rtl==true){
					left: 1.25rem;
                }
                top: 1.25rem;
                height: 22px;
                width: 22px;
                border-radius: 50%;
            }
            &:before {
                content:'';
                border: 2px solid $border-color;
            }
            &:after {
                line-height: 22px;
                font-size: 12px;
                font-family: $nk-dashlite-font;
                content: $ni-check-thick;
                color: #fff;
                background: $accent-color;
                text-align: center;
                opacity: 0;
                transition: opacity 0.4s;
            }
        } 
        &:checked ~ label {
            border-color: $accent-color;
            &:after {
                opacity: 1;
            }
        }  
    }
    &-desc{
		@if($rtl==false){
			text-align: left;
		}
		@if($rtl==true){
			text-align: right;
		}
		display: flex;
        justify-content: space-between;
        border: 1px solid $border-color;
        border-radius: $border-radius;
        flex-direction: column;
        .badge {
        	vertical-align: middle;
			@if($rtl==false){
				margin-left: .5rem;
			}
			@if($rtl==true){
				margin-right: .5rem;
			}
        }
	}
	&-title {
		color: $base-color;
		font-size: $h5-fs;
		display: block;
		font-weight: $fw-medium;
	}
	&-detail {
		color: $base-light;
	}
    &-action{
		margin-top: 1.5rem;
        .btn, .link {
            margin: 0 0.75rem;
        }
	}
    &-info, &-price {
		padding:1.25rem;
    }
    &-price {
		@if($rtl==false){
			text-align: left;
		}
		@if($rtl==true){
			text-align: right;
		}
		background: $lighter;
        border-radius: 0 0 $border-radius $border-radius;
        border-top: 1px solid $border-light;
        min-width: 185px;
    }
    &-amount {
    	font-size: $fx-sz-13;
        font-weight: $fw-medium;
        line-height: 1.1;
		.amount {
			display: block;
			font-size: 1.75rem;
			& + span {
				margin-top: .5rem;
				display: block;
			}
		}
	}
	
}

@include media-breakpoint-up(lg) {
	.sp-package {
		&-desc{
			@if($rtl==false){
				text-align: left;
				padding-left: 2.75rem;
			}
			@if($rtl==true){
				text-align: right;
				padding-right: 2.75rem;
			}
			flex-direction: row;
		}
		&-info, &-price {
			padding: 1.5rem 1.25rem;
	    }
		&-info {
			flex-grow: 1;
		}
		&-price {
			text-align: center;
			border-radius: 0 $border-radius $border-radius 0;
			border-top: 0;
			@if($rtl==false){
				border-left: 1px solid $border-light;
			}
			@if($rtl==true){
				border-right: 1px solid $border-light;
			}
		}
		&-choose {
			~ label {
				&:after, &:before {
					@if($rtl==false){
						left: 1.25rem;
						right: auto;
					}
					@if($rtl==true){
						right: 1.25rem;
						left: auto;
					}
					top: 50%;
			    	transform: translateY(-50%);
			    }
		    }
	    }
	}
}