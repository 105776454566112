// KYC APPICATION
///////////////////////
.nk-kyc-app{
    &-icon{
        margin-bottom: 2rem;
        .icon{
            display: inline-flex;
            align-items: center;
            justify-content: center;
            height: 80px;
            width: 80px;
            font-size: 36px;
            border-radius: 50%;
            color: $base-light;
            border: 2px solid $border-color;
        }
    }
    &-text{
        &:not(:last-child){
            margin-bottom: 2rem;
        }
    }
}

// KYC FORM
//////////////////////////
.nk-kycfm{
    &-head{
        display: flex;
        align-items: center;
    }
    &-head,&-content,&-footer{
        padding: 1.5rem;
        &:not(:last-child){
            border-bottom: 1px solid $border-light;
        }
    }
    &-count{
        display: inline-flex;
        align-items: center;
        justify-content: center;
        height: 44px;
        width: 44px;
        font-size: 16px;
        border-radius: 50%;
        color: $base-text;
        border: 2px solid $border-color;
        @if($rtl==false){
            margin-right: 1rem;
        }
        @if($rtl==true){
            margin-left: 1rem;
        }
        flex-shrink: 0;
    }
    &-title{
        .title{
            margin: .25rem 0;
        }
    }
    &-content{
        .title{
            margin-bottom: .75rem;
        }
    }
    &-note{
        color: $base-light;
        display: flex;
        &:not(:last-child){
            margin-bottom: 1rem;
        }
        .icon{
            @if($rtl==false){
                margin-right: 0.5rem;
            }
            @if($rtl==true){
                margin-left: 0.5rem;
            }
            font-size: 0.875rem;
            line-height: $line-height-base;
        }
    }
    &-label{
        display: flex;
        align-items: center;
        border:2px solid $border-light;
        border-radius: $border-radius;
        @if($rtl==false){
            padding: .5rem 2.875rem .5rem 1rem;
        }
        @if($rtl==true){
            padding: .5rem 1rem .5rem 2.875rem;
        }
        font-size: 13px;
        line-height: 1.3;
        letter-spacing: -0.01rem;
        font-weight: $fw-medium;
        cursor: pointer;
        transition: all .3s;
        margin-bottom: 0;
        &::after{
            position: absolute;
            @if($rtl==false){
                right: 1.375rem;
            }
            @if($rtl==true){
                left: 1.375rem;
            }
            top: 50%;
            height: 20px;
            width: 20px;
            line-height: 20px;
            font-size: 12px;
            border-radius: 50%;
            transform: translateY(-50%);
            font-family: $nk-dashlite-font;
            content: $ni-check;
            color:$white;
            background:$accent-color;
            display: flex;
            align-items: center;
            justify-content: center;
            opacity: 0;
            transition: opacity .3s;
        }
        &-icon{
            position: relative;
            width: 34px;
            display: flex;
            align-items: center;
            flex-shrink: 0;
            @if($rtl==false){
                margin-right: 12px;
            }
            @if($rtl==true){
                margin-left: 12px;
            }
            .label-icon{
                transition: all .3s;
                color: $base-light;
                width: 100%;
                height: 28px;
                svg{

                }
            }
        }
    }
    &-control{
        position: absolute;
        height: 1px;
        width: 1px;
        opacity: 0;
        &-list{
            display: flex;
            flex-wrap: wrap;
            + *{
                margin-top: 1.25rem;
            }
        }
        &-item{
            position: relative;
            flex-grow: 1;
        }
        &:checked ~ {
            .nk-kycfm-label{
                border-color: $accent-color;
                &:after{
                    opacity: 1;
                }
                .label-icon{
                    color: $accent-color;
                }
            }
        }
    }
    &-upload{
        &:not(:first-child){
            margin-top: 1.5rem;
        }
    }
}

@include media-breakpoint-up(sm){
    .nk-kycfm{
        &-head,&-content,&-footer{
            padding: 1.5rem 2.25rem;
        }
        &-content{
            padding-bottom: 1.75rem;
        }
        &-footer{
            padding: 2.25rem;
        }
    }
}