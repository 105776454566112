// SET RTL OPTION
$rtl: false;

// Included Global Variables and Functions
@import "dashlite_variables";

// re-write some variables
$success: #28a745;

// Included Fonts
/** 01. FONTS */
@import "core/fonts/roboto";
@import "core/fonts/nunito";

// Included Bootstarp
@import "extend/bootstrap/variables";
@import "extend/bootstrap/bootstrap";
@import "extend/bootstrap/extend";


// Include NioIcon
@import "vendors/nioicon/nioicon";

html,
body {
  //font-family: 'Muli';
  background: $border-light-alt;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  //font-family: 'Muli';
}

strong {
  font-weight: 600;
}

.text-white-75 {
  color: rgba(255, 255, 255, 0.75);
}

.text-white-90 {
  color: rgba(255, 255, 255, 0.9);
}

.bg-black-60 {
  background-color: rgba(0, 0, 0, 0.6);
}

.btn {
  font-weight: 600;
}

.text-uppercase {
  letter-spacing: .0625rem;
}

.fa-ul li {
  margin-bottom: .75rem;
}

a,
a:focus,
a:hover {
  text-decoration: none;
}

#page-container {
  width: 100%;
  margin: 0 auto;
  background-color: #fff;
}

.hero {
  overflow: hidden;
  position: relative;
  background: #004e92 linear-gradient(65deg, #000428, #004e92);
}

.bg-primary-dark {
  background: #000428;
}

.bg-primary-light {
  background: #fbfcff;
}

.nav-header .nav-link {
  padding: .25rem 0;
  font-weight: 600;
  color: rgba(255, 255, 255, 0.5);
  background-color: transparent;
  border-bottom: .125rem solid transparent;
}

.nav-header .nav-link:hover, .nav-header .nav-link:focus {
  color: white;
}

.nav-header .nav-link + .nav-link {
  margin-left: 1.5rem;
}

.nav-header .nav-link.active {
  color: #fff;
  border-bottom-color: #fff;
}

.text-yellow {
  color: $yellow !important;
}

.text-green {
  color: $success !important;
}

.bittencookie {
  color: $bittencookie-color !important;
}
